
import * as React from 'react'
import {Helmet} from "react-helmet";

const EmployeeReturns = () => (
  <div>
    <Helmet>
      <script>window.location.href='/shipper/employee-returns'</script>
    </Helmet>
  </div>
)

export default EmployeeReturns